import 'slick-carousel/slick/slick.min';
import 'magnific-popup/dist/jquery.magnific-popup.min';
import Cookies from 'js-cookie';
import objectFitImages from 'object-fit-images';

$(() => {
    // Object fit images
    const coverImages = document.querySelectorAll('.banner-slider__visual img');
    objectFitImages(coverImages, { watchMQ: true });

    // Content slider
    $('.banner-slider__visual').slick({
        arrows: false,
        autoplay: true,
        draggable: false,
        pauseOnHover: false,
        fade: true,
    });

    // Lightbox : Youtube
    $('[data-lightbox-video], .banner a[href*="youtube"]').magnificPopup({
        type: 'iframe',
        removalDelay: 300,
        preloader: false,
        fixedContentPos: false,
    });

    // Lightbox : Inline
    $('[data-lightbox-modal]').magnificPopup({
        type: 'inline',
        removalDelay: 300,
    });

    // Lightbox : Iframe
    $('[data-lightbox-iframe]').magnificPopup({
        type: 'iframe',
        removalDelay: 300,
    });

    // Magnific Popup for contao
    // @see https://github.com/fritzmg/contao-magnific-popup
    (function () {
        // get all lightbox links
        const $a = $('a[data-lightbox]');

        // determine all lightbox groups
        const groups = [];
        $a.each(function () {
            const $this = $(this);
            if ($.inArray($this.data('lightbox'), groups) === -1) {
                groups.push($this.data('lightbox'));
            }
        });

        // valid types
        const types = ['image', 'iframe', 'inline', 'ajax'];

        // go through each group
        $.each(groups, (index, group) => {
            // default options
            const defaults = {
                type: 'image',
                gallery: { enabled: true },
                overflowY: 'scroll',
                removalDelay: 300,
                image: {
                    titleSrc: 'data-title',
                },
                callbacks:
                    {
                        open() {
                            $('html').addClass('mfp-open');
                        },
                        close() {
                            $('html').removeClass('mfp-open');
                        },
                        buildControls() {
                            if (this.arrowLeft) {
                                this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
                            }
                        },
                    },
            };

            // extend defaults with user options
            const settings = $.extend(true, {}, defaults);

            // get the elements
            const $group = $(`a[data-lightbox="${group}"]`);

            // parse the group name
            const names = group.toString().split(' ');
            let width = null;
            let height = null;
            $.each(names, (i, name) => {
                // check for type
                if ($.inArray(name, types) !== -1) {
                    $.extend(settings, { type: name });
                }

                // check for external & social
                if (name === 'external' || name === 'social') {
                    $.extend(settings, { type: 'iframe' });
                }

                // check for width and height
                if (name === parseInt(name, 10)) {
                    if (width === null) width = parseInt(name, 10);
                    else if (height === null) height = parseInt(name, 10);
                }
            });

            // set width and height in markup for iframe
            if (settings.type === 'iframe' && (width !== null && height !== null)) {
                let style = 'style="position:relative;margin:auto;';
                if (width !== null) style += `max-width:${width}px;`;
                if (height !== null) style += `height:${height}px;padding:0;`;
                style += '"';
                const markup = `<div class="mfp-iframe-scaler" ${style}>`
                    + '<div class="mfp-close"></div>'
                    + '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'
                    + '</div>';
                $.extend(settings, { iframe: { markup } });
            }

            // init magnific popup
            $group.magnificPopup(settings);
        });
    }());

    // Image gallery module
    $('[data-gallery]').each(() => {
        const images = gallery.find('[data-gallery-image]');

        function updatePopupsGallery() {
            images.find('a').removeAttr('data-lightbox-gallery');

            images.filter(filterImage).each((i, el) => {
                $(el).find('a').attr('data-lightbox-gallery', '');
            });

            $('[data-lightbox-gallery]').magnificPopup({
                type: 'image',
                removalDelay: 300,
                image: {
                    titleSrc: 'data-title',
                },
                gallery: {
                    enabled: true,
                },
                callbacks: {
                    buildControls() {
                        if (this.arrowLeft) {
                            this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
                        }
                    },
                },
            });
        }

        // Update a popup gallery for the active category
        updatePopupsGallery();
    });

    const popupForms = {};

    // Popups (manual)
    $('[data-app-popup-manual-trigger]').on('click', function (e) {
        e.preventDefault();

        const trigger = $(this);
        const popupId = trigger.data('app-popup-manual-trigger');
        const popup = $(`[data-app-popup-manual="${popupId}"]`).eq(0);
        const content = popup.find('[data-app-popup-content]').eq(0);
        const formWrapper = content.find('.ce_form').eq(0);

        if (formWrapper.length > 0) {
            // Restore the original form from the cache
            if (popupForms[popupId] !== undefined) {
                formWrapper.html('').append(popupForms[popupId].form.clone(true));
                // eslint-disable-next-line no-eval
                eval(popupForms[popupId].script);
            } else {
                popupForms[popupId] = {
                    form: formWrapper.find('form').eq(0).clone(true),
                    script: formWrapper.find('script[data-app-form-script]').eq(0).text(),
                };
            }

            const email = trigger.data('app-popup-manual-email');
            const emailField = formWrapper.find('input[type="hidden"][name="recipient"]');

            // Dynamically set the recipient e-mail address if available (e.g. rsce_boxes_team)
            if (email && email.length > 0) {
                if (!emailField.data('app-original-email')) {
                    emailField.data('app-original-email', emailField.val());
                }

                emailField.val(email);
            } else {
                // Restore the original e-mail if none was explicitly provided
                emailField.val(emailField.data('app-original-email'));
            }

            const job = trigger.data('app-popup-manual-job');
            const jobField = formWrapper.find('input[type="text"][name="job_openings"]');

            // Dynamically set the job name if available (e.g. rsce_job_offer)
            if (job && job.length > 0) {
                if (!jobField.data('app-original-job')) {
                    jobField.data('app-original-job', jobField.val());
                }

                jobField.val(job);
            } else {
                // Restore the original e-mail if none was explicitly provided
                jobField.val(jobField.data('app-original-job'));
            }
        }

        $.magnificPopup.open({
            mainClass: popup.data('app-popup-class') || '',
            removalDelay: 300,
            items: {
                type: 'inline',
                src: content,
            },
        });
    });

    // Popups (auto)
    $('[data-app-popup-auto]').each(function () {
        const popup = $(this);
        const config = popup.data('app-popup-auto');
        const cookieName = `popup-${config.id}`;

        // eslint-disable-next-line no-inner-declarations
        function openPopup() {
            $.magnificPopup.open({
                closeOnBgClick: false,
                mainClass: config.cssClass || '',
                removalDelay: 300,
                items: {
                    type: 'inline',
                    src: popup.children('[data-app-popup-content]').eq(0),
                },
                callbacks: {
                    close() {
                        Cookies.set(cookieName, 1, config.interval ? { expires: config.interval } : {});
                    },
                },
            });
        }

        // Remove the popup element from DOM if cookie is present
        if (Cookies.get(cookieName)) {
            popup.remove();
        } else {
            let popupOpen = false;

            // Initialize the popup
            // eslint-disable-next-line default-case
            switch (config.type) {
            // Show after delay
            case 'delay': {
                setTimeout(openPopup, config.delay * 1000);
                break;
            }

            // Show on page scroll
            case 'pageScroll': {
                const initialScroll = $(window).scrollTop();
                const scrollThreshold = 150;

                $(window).on('scroll', () => {
                    if (!popupOpen && Math.abs(initialScroll - $(window).scrollTop()) > scrollThreshold) {
                        popupOpen = true;
                        openPopup();
                    }
                });
                break;
            }

            // Show when hover over URL address bar
            case 'hoverUrlBar': {
                let mouseY = 0;

                $(document).on('mousemove', (e) => {
                    mouseY = e.clientY;
                });

                $(document).on('mouseleave', () => {
                    if (!popupOpen && mouseY < 100) {
                        popupOpen = true;
                        openPopup();
                    }
                });
                break;
            }
            }
        }
    });
});
